export default {
  props: {
    modelValue: {},
    list: {}, // prevent inheritence of list="" on element
    characterCounter: {
      type: [Object, Boolean],
      default: false,
    },
    maxCharacters: {
      type: [Boolean, Number],
      default: false,
    },
  },
  data: function () {
    return {
      internalCharacterCounter: {
        max: this.maxCharacters,
        current: this.modelValue ? this.modelValue?.length : 0,
      },
    };
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$nextTick(() => {
          try {
            this.internalCharacterCounter.current = this.modelValue.length;
          } catch (e) {}
        });
      },
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      return attrs;
    },
    hasCharacterCounter() {
      if (this.maxCharacters || this.maxCharacters === 0) {
        return true;
      }

      return typeof this.characterCounter === "object" && this.characterCounter !== null;
    },
    maxCharacters() {
      if (this.characterCounter) {
        return this.characterCounter.max || this.translate("core.unlimited");
      } else {
        return this.internalCharacterCounter.max || this.translate("core.unlimited");
      }
    },
    currentCharacters() {
      return this.value ? this.value.length : 0;
    },
  },
};
